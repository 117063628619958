import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { Input, message, Popconfirm, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faCopy, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

import { createAPIToken, revokeAPIToken } from '../../redux/actions/profileActions';
import { warningMessage } from '../../shared/Components/PaymentInfo';

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default function APIToken({ token, loading, disabled, accountNotValid }) {
  const isDisabled = (loading || disabled);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function onClickFromInput() {
    if (token) {
      onCopy();
    } else {
      onGenerateAPIToken();
    }
  }

  function onGenerateAPIToken() {
    if (accountNotValid) {
      message.warning(warningMessage, 10);
    } else {
      dispatch(createAPIToken());
    }
  }

  function onRevokeAPIToken() {
    dispatch(revokeAPIToken());
  }

  function onCopy() {
    var apiToken = document.querySelector('.api-token');
    apiToken.select();
    document.execCommand('copy');

    message.success(t('title.copiedToClipboard'));
  }

  return <>
    <Input
      readOnly
      className='api-token copieble'
      value={token ? token : t('title.generateAPItoken')}
      disabled={isDisabled}
      onClick={onClickFromInput}
    />
    <StyledButton
      title={t('title.copyToClipboard')}
      icon={<FontAwesomeIcon icon={faCopy}/>}
      onClick={onCopy}
      disabled={isDisabled || !token}
    />
    <Popconfirm
      title={t('title.generateNewToken') + (token ? t('profile.previousTokenRevoked') : '')}
      onConfirm={onGenerateAPIToken}
      disabled={isDisabled}
    >
      <StyledButton
        title= {t('title.changeAPIToken')}
        icon={<FontAwesomeIcon icon={faSync}/>}
        disabled={isDisabled}
      />
    </Popconfirm>
    { token && (
      <Popconfirm
        title={`${t('title.RevokeAPIToken')}?`}
        onConfirm={onRevokeAPIToken}
        disabled={isDisabled}
      >
        <StyledButton
          title={t('title.RevokeAPIToken')}
          icon={<FontAwesomeIcon icon={faTrash}/>}
          disabled={isDisabled}
        />
      </Popconfirm>
    )}
  </>;
}

APIToken.propTypes = {
  token: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  accountNotValid: PropTypes.bool,
};
