import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Badge, Space } from 'antd';
import { handleOnCopy } from '../../../utils/helper';
import { CheckCircle } from '../../../assets/icons';

const statusesMap = {
  operational: 'processing',
  closed_permanently: 'error',
  closed_temporarily: 'warning',
  Operational: 'processing',
  'Closed permanently': 'error',
  'Closed temporarily': 'warning'
};

export default function PlaceTitle({ value, onExport }) {
  const { display_name, business_status = '', business_status_history, verified, owner_title } = value;

  function onCopy(e) {
    if (!handleOnCopy(e) && onExport) {
      onExport();
    }
  }

  const truncatedTitle = display_name.length > 20 ? `${display_name.slice(0, 20)}...` : display_name;

  return (
    <Space align='center' onCopy={onCopy}>
      <Tooltip
        title={<>
                Status: <strong><em>{business_status && business_status.replace('_', ' ')}</em></strong>
          {business_status_history && <>
            <br/><br/>
                    history:
            <br/>
            {business_status_history.map((el, i) => <div key={`${el}-${i}`}>{el.replace('_', ' ')}<br/></div>)}
          </>}
        </>}>
        <Badge status={statusesMap[business_status]} title='Active' />
      </Tooltip>
      <h2 className='h2'>
        {truncatedTitle}
      </h2>
      {verified &&
        <Tooltip title={`Claimed by owner (${owner_title})`}>
          <div className='icon-container'>
            <CheckCircle />
          </div>
        </Tooltip>
      }
    </Space>
  );
}

PlaceTitle.propTypes = {
  value: PropTypes.object.isRequired,
  onExport: PropTypes.func,
};
