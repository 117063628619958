import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { notification, message, Input, Card, Form, InputNumber, Button } from 'antd';
import { useParams } from 'react-router-dom';

import apiPayment from '../../../../api/apiPayment';
import { useTranslation, Trans } from 'react-i18next';

const StyledInput = styled(Input)`
  width: 200px;
`;

const StyledAddressInput = styled(Input)`
  width: 400px;
  background-color: #f5fcff;
`;

const { Meta } = Card;

export default function CryptoPayment() {
  const { paymentId } = useParams();
  const { t } = useTranslation();
  const [paymentDetails, setPaymentDetails] = useState({});
  const [loading, setLoading] = useState(false);

  const { payment_status, pay_address, actually_paid, price_amount, price_currency, pay_amount, pay_currency } = paymentDetails;
  const payCurrency = pay_currency ? pay_currency.toUpperCase() : '';
  const description = (
    <Trans i18nKey='cryptoPayment.description' values={{ pay_amount, payCurrency }}>
      Please send <strong>{pay_amount}</strong> {payCurrency} (<strong>exact amount</strong>) to the deposit address displayed below. The deposit will be credited to your account balance.
    </Trans>
  );

  const priceCurrency = price_currency ? price_currency.toUpperCase() : '';
  const isPaid = actually_paid > 0;

  useEffect(() => {
    if (paymentId) {
      fetchData();

      setInterval(() => {
        fetchData(false);
      }, 30000);
    }
  }, [paymentId]);

  async function fetchData(showLoading = true) {
    if (showLoading) setLoading(true);
    try {
      const data = await apiPayment.getCryptoPayment(paymentId);
      setPaymentDetails(data);
    } catch (error) {
      notification.error({ message: error.message });
    } finally {
      if (showLoading) setLoading(false);
    }
  }

  function onCopy(selector, copyText) {
    var element = document.querySelector(selector);
    element.select();
    document.execCommand('copy');

    message.success(`${copyText} ` + t('cryptoPayment.copiedToClipboard'));
  }

  return (
    <Card
      title={`${t('cryptoPayment.title', 'Payment')} #${paymentId} [${payment_status}]`}
      extra={<Button loading={loading} onClick={fetchData} type='default'><Trans i18nKey='title.refresh' /></Button>}
      style={{ width: 450 }}
    >
      <Meta description={isPaid ?
        `${t('cryptoPayment.message1')} ${actually_paid} ${payCurrency}.${t('cryptoPayment.message2')}` : description} />
      <br />
      <Form name='basic' layout='vertical'>
        <Form.Item label={`${payCurrency} amount`}>
          <StyledInput
            readOnly
            className='pay-amount'
            value={pay_amount}
            onClick={() => onCopy('.pay-amount', `${payCurrency} ${t('title.amount')}`)}
            disabled={loading}
          />
        </Form.Item>
        <Form.Item label={`${priceCurrency} ${t('title.amount')}`}>
          <StyledInput
            readOnly
            className='price-amount'
            value={price_amount}
            onClick={() => onCopy('.price-amount', `${priceCurrency} ${t('title.amount')}`)}
            disabled={loading}
          />
        </Form.Item>
        <Form.Item label={`${payCurrency} ${t('title.address')}`}>
          <StyledAddressInput
            readOnly
            className='pay-address'
            value={pay_address}
            onClick={() => onCopy('.pay-address', `${payCurrency} ${t('title.address')}`)}
            disabled={loading}
          />
        </Form.Item>
        {isPaid &&
          <Form.Item label={`${payCurrency} ${t('cryptoPayment.actuallyPaid')}`}>
            <InputNumber
              readOnly
              style={{ width: 250 }}
              className='actually-paid'
              value={actually_paid}
              disabled={loading}
            />
          </Form.Item>
        }
      </Form>
    </Card>
  );
}
