import React, { useState } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import {
  Button,
  Col,
  Collapse,
  Input,
  Modal,
  Row,
  Select,
  Form,
  Radio,
  Checkbox,
  Space,
  DatePicker,
  Slider,
  InputNumber,
  Switch
} from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import './AdvancedFiltersModal.scss';
import InfoTooltip from '../../../shared/Components/InfoTooltip';
import PremiumFeatureTooltip from '../../../shared/Components/PremiumFeatureTooltip';
import { checkIfPaidUser, areArraysEqual } from '../../../utils/utils';
import SelectPostalCode from '../../Common/SelectPostalCode';
import AttributesFilter from '../../Common/AttributesFilter';
import SelectType from '../../Common/SelectType';
import { Chart, Contacts, Date, Laptop, Location, Office } from '../../../assets/icons';
import {
  areaOptions,
  businessStatusOptions,
  cmsGeneratorOptions,
  companyTypeOptions,
  contactCategoryOptions,
  emailAndPhoneOptions,
  emailAndPhoneUniqueDomainOptions,
  emailAndPhoneUniquePhoneOptions,
  employeesMarks, employeesMarksMinMax,
  employeesValues,
  ratingMarks, ratingMarksMinMax,
  revenueMarks, revenueValues, revenueMarksMinMax,
  reviewsMarks, reviewsMarksWithPlus, reviewsMarksMinMax, verificationOptions, websiteOptions
} from '../../../shared/constants/filters.constants';

const { RangePicker } = DatePicker;

const uniqueInfoTooltip = <InfoTooltip
  title={<>Selecting this will return only one entry per business, even if they have multiple locations using the same email and domain.</>}
/>;

export default function AdvancedFiltersModal({ isModalOpen, onClose, searchParams, size, onChangeHandle }) {
  const [tempFilters, setTempFilters] = useState(searchParams);
  const uniquePhoneEnabled = emailAndPhoneUniquePhoneOptions.has(tempFilters.emailAndPhone);
  const formattedAddedFrom = tempFilters.addedFrom ? dayjs(tempFilters.addedFrom * 1000) : null;
  const formattedAddedTo = tempFilters.addedTo ? dayjs(tempFilters.addedTo * 1000) : null;
  const isPaidUser = checkIfPaidUser();
  const { t } = useTranslation();

  const isDomainSite = tempFilters.site === 'domain';
  const isTrueSite = tempFilters.site === 'true';
  const shouldShowWebsiteAdditions = isDomainSite || isTrueSite || tempFilters.domain;

  const isEnterPhone = tempFilters.emailAndPhone === 'enter_phone';
  const isWithEmail = tempFilters.emailAndPhone === 'with_email';
  const hasNumber = !!tempFilters.number;

  const shouldShowPhoneAdditions = isEnterPhone || isWithEmail || hasNumber || uniquePhoneEnabled;
  const shouldShowUniqueDomainCheckbox = isWithEmail;
  const shouldShowPhoneInput = isEnterPhone || hasNumber;
  const shouldDisableUniquePhone = !hasNumber && isEnterPhone;

  const handleApply = () => {
    onChangeHandle(tempFilters);
    onClose();
  };

  const handleFieldChange = (fieldValues, isRange = false) => {
    setTempFilters((prev) => {
      const updatedFilters = { ...prev };

      for (const [field, value] of Object.entries(fieldValues)) {
        if (isRange && Array.isArray(value)) {
          updatedFilters[field] = value.join('-');
        } else {
          updatedFilters[field] = value;
        }
      }
      return updatedFilters;
    });
  };

  const handleOnReviewsChange = (value) => {
    if (areArraysEqual(value, reviewsMarksMinMax)) {
      value = undefined;
    }

    handleFieldChange({ 'reviews': value }, true);
  };

  const handleOnRatingChange = (value) => {
    if (areArraysEqual(value, ratingMarksMinMax)) {
      value = undefined;
    }

    handleFieldChange({ 'rating': value }, true);
  };

  const handleOnEmployeesChange = (value) => {
    const newEmployees = [employeesValues[value[0]], employeesValues[value[1]]];
    handleFieldChange({
      employees: areArraysEqual(newEmployees, employeesMarksMinMax) ? undefined : newEmployees
    }, true);
  };

  const handleOnRevenueChange = (value) => {
    const newRevenue = [revenueValues[value[0]], revenueValues[value[1]]];
    handleFieldChange({
      revenue: areArraysEqual(newRevenue, revenueMarksMinMax) ? undefined : newRevenue
    }, true);
  };

  const handleEmailAndPhoneChange = (v) => {
    const isUniqueDomain = emailAndPhoneUniqueDomainOptions.has(v);
    const isUniquePhone = emailAndPhoneUniquePhoneOptions.has(v);
    const updatedParams = {
      emailAndPhone: v,
      uniqueDomain: isUniqueDomain ? tempFilters.uniqueDomain : undefined,
      number: v === 'enter_phone' ? tempFilters.number : undefined,
      uniquePhone: isUniquePhone ? tempFilters.uniquePhone : undefined,
    };

    handleFieldChange(updatedParams);
  };

  function disabledCutoffDate(current) {
    return current > dayjs().subtract(7, 'day');
  }

  function onRangeChange(dates) {
    const [startDate, endDate] = dates || [];
    const start = startDate ? startDate.unix() : undefined;
    const end = endDate ? endDate.unix() : undefined;

    handleFieldChange({
      addedFrom: start,
      addedTo: end,
    });
  }

  const handleCheckboxChange = (e) => (e.target.checked) ? handleFieldChange({ businessOnly: true }) : handleFieldChange({ businessOnly: undefined });

  const getLabelWithPremiumTooltip = (label) => (
    <PremiumFeatureTooltip isPaidUser={isPaidUser}>
      {label}
    </PremiumFeatureTooltip>
  );

  const businessNameLabelWithSwitch = (
    <Space>
      <span><Trans i18nKey='advancedFilters.businessName' /></span>
      <Switch
        checkedChildren={t('title.included')}
        unCheckedChildren={t('title.excluded')}
        checked={!tempFilters.nameExclude}
        onChange={() => handleFieldChange({ 'nameExclude': tempFilters.nameExclude ? undefined : true })}
      />
    </Space>
  );

  const handleReset = () => {
    setTempFilters(searchParams);
    onClose();
  };

  const collapseItemLabel = (title, icon) =>
    <Space className='align-items-space'>
      {icon}
      <span>{title}</span>
    </Space>;

  const getSelectedFiltersCount = (filters, keys) => {
    return keys.reduce((count, key) => {
      const value = filters[key];
      if (Array.isArray(value)) {
        return count + value.length;
      } else if (value) {
        return count + 1;
      }
      return count;
    }, 0);
  };

  const reviewsValue = tempFilters.reviews?.split('-').map(Number) || [0, 1000];

  const items = [
    {
      key: '1',
      label: collapseItemLabel(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Office style={{ marginRight: '8px' }} />
          {t('advancedFilters.businessInfo')}
          {(() => {
            const filterCount = getSelectedFiltersCount(tempFilters, [
              'name',
              'businessStatus',
              'verified',
              'public',
            ]);
            return filterCount > 0 ? ` (${filterCount})` : null;
          })()}
        </div>,
      ),
      children:
        <Row gutter={[16, 16]} align='middle'>
          <Col xs={24} lg={12}>
            <Form.Item label={businessNameLabelWithSwitch}>
              <Input
                size={size}
                placeholder={t('advancedFilters.nameKeyword')}
                value={tempFilters.name}
                onChange={({ target: { value } }) => handleFieldChange({ 'name': value || undefined })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={t('advancedFilters.businessStatus')}>
              <Select
                className='w-100'
                placeholder={t('title.all')}
                allowClear
                options={businessStatusOptions}
                value={tempFilters.businessStatus}
                onChange={(value) => handleFieldChange({ 'businessStatus': value })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={t('advancedFilters.verification')}>
              <Space align='center'>
                <Radio.Group
                  options={verificationOptions}
                  value={tempFilters.verified}
                  onChange={(e) => handleFieldChange({ 'verified': e.target.value })}
                />
                <InfoTooltip title={t('advancedFilters.verificationInfo')} />
              </Space>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={t('advancedFilters.companyType')}>
              <Radio.Group
                options={companyTypeOptions}
                value={tempFilters.public}
                onChange={(e) => handleFieldChange({ 'public': e.target.value })}
              />
            </Form.Item>
          </Col>
        </Row>,
    },
    {
      key: '2',
      label: collapseItemLabel(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Contacts style={{ marginRight: '8px' }} />
          {t('advancedFilters.contactInfo')}
          {(() => {
            const filterCount = getSelectedFiltersCount(tempFilters, [
              'emailAndPhone',
              'contactCategory',
              'site',
            ]);
            return filterCount > 0 ? ` (${filterCount})` : null;
          })()}
        </div>
      ),
      children:
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item label={t('advancedFilters.emailPhone')}>
              <Row gutter={[16, 16]} className='alignCenter'>
                <Col xs={24} lg={12}>
                  <Select
                    placeholder={t('title.all')}
                    allowClear
                    options={emailAndPhoneOptions}
                    value={tempFilters.emailAndPhone}
                    onChange={(value) => handleEmailAndPhoneChange(value)}
                  />
                </Col>
                {(shouldShowPhoneAdditions) && (
                  <Col xs={24} lg={12}>
                    <Space className='w-100'>
                      {shouldShowPhoneInput && (
                        <Input
                          size={size}
                          placeholder='123'
                          value={tempFilters.number}
                          onChange={({ target: { value } }) =>
                            handleFieldChange({ number: value || undefined })
                          }
                        />
                      )}
                      {uniquePhoneEnabled && (
                        <Checkbox
                          disabled={shouldDisableUniquePhone}
                          checked={tempFilters.uniquePhone}
                          onChange={() =>
                            handleFieldChange({
                              uniquePhone: tempFilters.uniquePhone ? undefined : true,
                            })
                          }
                        >
                          <Trans i18nKey='advancedFilters.uniquePhone' />
                        </Checkbox>
                      )}
                      {/* {mobileFilterCountryCodes.has(cc) && (emailAndPhone === 'with_phone' || emailAndPhone === 'email_and_phone') &&
                          <Select
                            placeholder='Type'
                            allowClear
                            value={mobile}
                            style={{ ...advancedItemStyle, width: 100 }}
                            onChange={(v) => onChangeHandle({ ...searchParams, mobile: v })}
                          >
                            <Option key='true' value='true'>Mobile</Option>
                            <Option key='false' value='false'>Fixed line</Option>
                          </Select>
                        } */}
                      {shouldShowUniqueDomainCheckbox &&
                        <>
                          <Checkbox
                            checked={tempFilters.uniqueDomain}
                            onChange={() => handleFieldChange({ 'uniqueDomain': tempFilters.uniqueDomain ? undefined : true })}
                          ><Trans i18nKey='advancedFilters.uniqueOnly' /></Checkbox>
                          {uniqueInfoTooltip}
                        </>
                      }
                    </Space>
                  </Col>
                )}
              </Row>
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={getLabelWithPremiumTooltip(t('title.website'))}>
              <Row gutter={[16, 16]} className='alignCenter'>
                <Col xs={24} lg={12}>
                  <Select
                    className='w-100'
                    disabled={!isPaidUser}
                    placeholder={t('title.all')}
                    options={websiteOptions}
                    allowClear
                    value={tempFilters.domain ? 'domain' : tempFilters.site}
                    onChange={(v) => handleFieldChange({ site: v, domain: null, uniqueDomain: v === 'true' || tempFilters.email === 'true' ? tempFilters.uniqueDomain : undefined })}
                  />
                </Col>
                {shouldShowWebsiteAdditions && (
                  <Col xs={24} lg={12}>
                    {(isDomainSite || tempFilters.domain) &&
                      <Input
                        className='w-100'
                        size={size}
                        placeholder='starbucks.com'
                        value={tempFilters.domain}
                        onChange={({ target: { value } }) => handleFieldChange({ 'domain': value })}
                      />
                    }
                    {isTrueSite && <Space align='center'>
                      <Checkbox
                        checked={tempFilters.uniqueDomain}
                        onChange={() => handleFieldChange({ 'uniqueDomain': tempFilters.uniqueDomain ? undefined : true })}
                      ><Trans i18nKey='advancedFilters.uniqueOnly' /></Checkbox>
                      {uniqueInfoTooltip}
                    </Space>
                    }
                  </Col>
                )}
              </Row>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={getLabelWithPremiumTooltip(t('advancedFilters.preferredContacts'))}>
              <Select
                className='w-100'
                disabled={!isPaidUser}
                allowClear
                options={contactCategoryOptions}
                placeholder={t('title.all')}
                mode='tags'
                maxTagCount={1}
                tokenSeparators={[',']}
                value={tempFilters.contactCategory}
                onChange={(v) => handleFieldChange({ 'contactCategory': v })}
              />
            </Form.Item>
          </Col>
        </Row>,
    },
    {
      key: '3',
      label: collapseItemLabel(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Laptop style={{ marginRight: '8px' }} />
          {t('advancedFilters.onlinePresence')}
          {(() => {
            const filterCount = getSelectedFiltersCount(tempFilters, [
              'generator',
              'rating',
              'reviews',
            ]);
            return filterCount > 0 ? ` (${filterCount})` : null;
          })()}
        </div>
      ),
      children:
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Form.Item label={t('advancedFilters.cmsGenerator')}>
              <Select
                placeholder={t('advancedFilters.selectCMS')}
                allowClear
                options={cmsGeneratorOptions}
                value={tempFilters.generator}
                onChange={(v) => handleFieldChange({ 'generator': v })}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={t('title.raiting')}>
              <Slider
                tooltip={{ open: false }}
                range
                step={0.25}
                marks={ratingMarks}
                max={5}
                value={tempFilters.rating?.split('-').map(Number) || [0, 5]}
                onChange={handleOnRatingChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={t('title.reviews')}>
              <Space>
                <Space>
                  <span> <Trans i18nKey='advancedFilters.from' /></span>
                  <InputNumber
                    type='number'
                    value={reviewsValue[0]}
                    max={reviewsValue[1]}
                    onChange={(value) => handleOnReviewsChange([value, reviewsValue[1]])}
                  />
                </Space>
                <Space>
                  <span><Trans i18nKey='advancedFilters.to' /></span>
                  <InputNumber
                    type='number'
                    value={reviewsValue[1]}
                    min={reviewsValue[0]}
                    onChange={(value) => handleOnReviewsChange([reviewsValue[0], value])}
                  />
                </Space>
              </Space>
              <Slider
                tooltip={{ open: false }}
                range
                marks={reviewsValue[1] > 1000 ? reviewsMarksWithPlus : reviewsMarks}
                max={1000}
                value={reviewsValue}
                onChange={handleOnReviewsChange}
              />
            </Form.Item>
          </Col>
        </Row>,
    },
    {
      key: '4',
      label: collapseItemLabel(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Chart style={{ marginRight: '8px' }} />
          {t('advancedFilters.financialData')}
          {(() => {
            const filterCount = getSelectedFiltersCount(tempFilters, [
              'employees',
              'revenue',
            ]);
            return filterCount > 0 ? ` (${filterCount})` : null;
          })()}
        </div>
      ),
      children:
        <Row gutter={[16, 16]}>
          <Col xs={24}>
            <Form.Item label={getLabelWithPremiumTooltip(t('title.employees'))}>
              <Slider
                tooltip={{ open: false }}
                disabled={!isPaidUser}
                range
                step={null}
                marks={employeesMarks}
                max={5}
                value={
                  typeof tempFilters.employees === 'string'
                    ? tempFilters.employees.split('-').map(v => employeesValues.indexOf(Number(v)))
                    : [0, 5]
                }
                onChange={handleOnEmployeesChange}
              />
            </Form.Item>
          </Col>
          <Col xs={24}>
            <Form.Item label={t('title.revenue')}>
              <Slider
                disabled={!isPaidUser}
                tooltip={{ open: false }}
                range
                step={null}
                marks={revenueMarks}
                max={4}
                value={
                  typeof tempFilters.revenue === 'string'
                    ? tempFilters.revenue.split('-').map(v => revenueValues.indexOf(Number(v)))
                    : [0, 4]
                }
                onChange={handleOnRevenueChange}
              />
            </Form.Item>

          </Col>
        </Row>,
    },
    {
      key: '5',
      label: collapseItemLabel(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Location style={{ marginRight: '8px' }} />
          {t('advancedFilters.geoInfo')}
          {(() => {
            const filterCount = getSelectedFiltersCount(tempFilters, [
              'postalCode',
              'area',
            ]);
            return filterCount > 0 ? ` (${filterCount})` : null;
          })()}
        </div>
      ),
      children:
        <Row gutter={[16, 16]}>
          <Space direction='vertical' className='w-100'>
            <Col xs={24} lg={12}>
              <Form.Item label={t('title.postalCode')}>
                <SelectPostalCode
                  size={size}
                  value={tempFilters.postalCode}
                  onChange={(v) => handleFieldChange({ 'postalCode': v })}
                />
              </Form.Item>
            </Col>
            <Col xs={24}>
              <Form.Item label={t('title.location')}>
                <Radio.Group
                  options={areaOptions}
                  value={tempFilters.area}
                  onChange={(e) => handleFieldChange({ 'area': e.target.value })}
                />
              </Form.Item>
            </Col>
          </Space>
        </Row>,
    },
    {
      key: '6',
      label: collapseItemLabel(
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Date style={{ marginRight: '8px' }} />
          {t('advancedFilters.datesAttributes')}
          {(() => {
            const filterCount = getSelectedFiltersCount(tempFilters, [
              'att',
              'it',
              'addedFrom',
              'businessOnly'
            ]);
            return filterCount > 0 ? ` (${filterCount})` : null;
          })()}
        </div>
      ),
      children:
        <Row gutter={[16, 16]}>
          <Col xs={24} lg={12}>
            <Form.Item label={t('advancedFilters.attributes')}>
              <AttributesFilter
                value={tempFilters.att}
                filters={searchParams}
                onChange={(v) => handleFieldChange({ 'att': v })}
              />
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={t('title.exclude')}>
              <Space direction='vertical' align='start'>
                <SelectType
                  placeholder={t('advancedFilters.selectCategory')}
                  size={size}
                  value={tempFilters.it}
                  onChange={(v) => handleFieldChange({ 'it': v })}
                />
                <Checkbox
                  checked={tempFilters.businessOnly}
                  onChange={handleCheckboxChange}
                >
                  <Trans i18nKey='advancedFilters.businessOnly' />
                </Checkbox>
              </Space>
            </Form.Item>
          </Col>
          <Col xs={24} lg={12}>
            <Form.Item label={getLabelWithPremiumTooltip(t('advancedFilters.dateRange'))}>
              <Space className='full-width-space' align='center'>
                <RangePicker
                  className='w-100'
                  disabled={!isPaidUser}
                  format='YYYY/MM/DD'
                  value={[formattedAddedFrom, formattedAddedTo]}
                  onChange={onRangeChange}
                  disabledDate={disabledCutoffDate}
                />
                <InfoTooltip
                  title={
                    <>
                      {t('advancedFilters.newCompaniesHeader')} <br />
                      {t('advancedFilters.newCompaniesDescription')} <br /><br />
                      {t('advancedFilters.accuracyInfo')}
                    </>
                  }
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>,
    },
  ];

  return <Modal
    width={700}
    title={<h3 className='h3'><Trans i18nKey='advancedFilters.title' /></h3>}
    className='advanced-filters-modal'
    open={isModalOpen}
    onCancel={onClose}
    maskClosable={false}
    footer={[
      <Button key='back' onClick={handleReset} className='cancel'>
        <Trans i18nKey='title.reset' />
      </Button>,
      <Button key='submit' type='primary' className='confirm' onClick={handleApply}>
        <Trans i18nKey='title.apply' />
      </Button>,
    ]}>
    <Form colon={false} layout='vertical'>
      <Collapse defaultActiveKey={['1']} ghost items={items} />
    </Form>
  </Modal>;
}

AdvancedFiltersModal.propTypes = {
  isModalOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onChangeHandle: PropTypes.func,
  searchParams: PropTypes.object.isRequired,
  size: PropTypes.string,
};
