import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Card, Space, Button } from 'antd';
import { PageHeader } from '@ant-design/pro-layout';
import { LinkOutlined, DeleteOutlined } from '@ant-design/icons';
import { GoogleSheetsIcon } from '../../assets/icons';

import { connectIntegration, removeIntegration } from '../../redux/actions/profileActions';
import { isGoogleIntegrationExists } from '../../utils/utils';

const { Meta } = Card;

const cardStyle = { width: 510 };
const PageHeaderStyle = { border: '1px solid rgb(235, 237, 240)' };

export default function Integrations() {
  const { t } = useTranslation();
  const [googleIntegrationExists, setGoogleIntegrationExists] = useState(isGoogleIntegrationExists());
  const dispatch = useDispatch();
  const isLoading = useSelector(state => state.profileReducer.loading);

  async function handleConnectClick() {
    await connectIntegration(dispatch);
    setGoogleIntegrationExists(true);
  }

  async function handleRemoveClick() {
    await removeIntegration(dispatch);
    setGoogleIntegrationExists(false);
  }

  useEffect(() => {
    document.title = t('title.integrations');
    setGoogleIntegrationExists(isGoogleIntegrationExists());
  }, [t]);

  return (
    <Space direction='vertical' size='large' >
      <PageHeader
        style={PageHeaderStyle}
        title={t('title.integrations')}
        subTitle={t('integrations.description')}
      />

      <Card
        className='card-white-bg'
        style={cardStyle}
        actions={[
          !isGoogleIntegrationExists() ? (
            <Button
              type='text'
              icon={<LinkOutlined />}
              title={t('integrations.connect')}
              onClick={handleConnectClick}
              loading={isLoading}
            >
              {t('integrations.connect')}
            </Button>
          ) : (
            <Button
              type='text'
              icon={<DeleteOutlined />}
              title={t('integrations.remove')}
              onClick={handleRemoveClick}
              loading={isLoading}
            >
              {t('integrations.remove')}
            </Button>
          )
        ]}
      >
        <Meta
          title={
            <Space>
              <GoogleSheetsIcon />
              Google Sheets
            </Space>
          }
          description={t('integrations.googleDescription')}
        />
      </Card>
    </Space>
  );
}
