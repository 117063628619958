import React from 'react';
import PropTypes from 'prop-types';

import HidedValue from './HidedValue';
import { handleOnCopy } from '../../utils/helper';

const capitalizaStyle = { textTransform: 'capitalize' };

export default function Address({ value }) {
  const { country_code, country, cc, state, city, county, postal_code, postalCode, street } = value;
  const items = [country_code, country, cc, state, city, county, postal_code, postalCode].filter(el => el);
  return <span className='bold' style={capitalizaStyle} onCopy={handleOnCopy}>{items.join(', ')}{street && <> <HidedValue value={street}/></>}</span>;
}

Address.propTypes = {
  value: PropTypes.object.isRequired,
};
