import auth0 from 'auth0-js';
import { notification } from 'antd';

import { getCompanyConfig } from '../helpers/common';
import apiClient from '../api/apiClient';
import Mixpanel from '../analytics/mixpanel';
import { updateLocalStorage, addReferrerAndSource } from '../utils/utils';
import { sendSignUpEvent, sendLoginEvent } from '../analytics/gtm';

const COMPANY_CONFIG = getCompanyConfig();

export default class Auth {
  constructor() {
    this.initAuth0 = this.initAuth0.bind(this);
    this.login = this.login.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.setSession = this.setSession.bind(this);
    this.renewSession = this.renewSession.bind(this);
    this.logout = this.logout.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.isAdmin = this.isAdmin.bind(this);

    this.initAuth0();
  }

  initAuth0() {
    this.auth0 = new auth0.WebAuth({
      domain: COMPANY_CONFIG.auth0CustomDomain || COMPANY_CONFIG.auth0Domain,
      clientID: COMPANY_CONFIG.auth0ClientID,
      redirectUri: `${window.origin}/callback`,
      audience: `https://${COMPANY_CONFIG.auth0Domain}/api/v2/`,
      responseType: 'token id_token',
      scope: 'openid profile email',
    });
  }

  login() {
    const payload = {};
    addReferrerAndSource(payload);
    this.auth0.authorize(payload);
  }

  signup() {
    const payload = { screen_hint: 'signup' };
    addReferrerAndSource(payload);
    this.auth0.authorize(payload);
  }

  handleAuthentication() {
    this.auth0.parseHash((error, authResult) => {
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult, true);
      } else if (error) {
        console.error(error);
        alert(`${error.errorDescription}`);
        this.logout();
      }
    });
  }

  setSession(authResult, useRedirect=false) {
    const expiresAt = (authResult.expiresIn * 1000) + new Date().getTime();

    apiClient.defaults.headers.common = { Authorization: `Bearer ${authResult.accessToken}` };
    localStorage.setItem('isLoggedIn', '1');
    localStorage.setItem('expiresAt', expiresAt);
    localStorage.setItem('idToken', authResult.idToken);
    localStorage.setItem('accessToken', authResult.accessToken);

    const { idTokenPayload } = authResult;
    if (idTokenPayload) {
      const { email, picture, email_verified, sub } = idTokenPayload;
      const ie = idTokenPayload[COMPANY_CONFIG.auth0PayloadKey + '/ie'];
      const provider = sub ? sub.split('|')[0] : '';

      updateLocalStorage('email', email);
      updateLocalStorage('id', sub);
      updateLocalStorage('avatar', picture);
      updateLocalStorage('roles', idTokenPayload[COMPANY_CONFIG.auth0PayloadKey + '/roles'].join());
      updateLocalStorage('emailVerified', email_verified);
      updateLocalStorage('provider', provider);
      updateLocalStorage('ie', ie);

      Mixpanel.identify(sub);
      if (authResult.screen_hint === 'signup') {
        sendSignUpEvent();
      } else {
        sendLoginEvent();
      }
    }

    const redirect = localStorage.getItem('redirect');
    if (useRedirect && redirect) {
      localStorage.removeItem('redirect');
      window.location = redirect;
    }
  }

  async renewSession(callback=null) {
    this.auth0.checkSession({}, (err, authResult) => {
      if (authResult && authResult.idTokenPayload && !authResult.idTokenPayload.email_verified) {
        notification.warning({ message: 'Please check your inbox and verify your email' });
      }
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.setSession(authResult);
        if (callback) callback();
      } else if (err) {
        // this.logout();
        console.error(err);
        // alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
      }
    });
  }

  logout() {
    localStorage.removeItem('isLoggedIn');
    localStorage.removeItem('expiresAt');
    localStorage.removeItem('idToken');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('email');
    localStorage.removeItem('avatar');
    localStorage.removeItem('roles');
    localStorage.removeItem('ie');
    localStorage.removeItem('id');
    localStorage.removeItem('emailVerified');
    localStorage.removeItem('provider');

    this.auth0.logout({ returnTo: window.location.origin });
  }

  isAuthenticated() {
    const expiresAt = localStorage.getItem('expiresAt');
    if (expiresAt) {
      return new Date().getTime() < Number(expiresAt);
    }

    return false;
  }

  isAdmin() {
    const roles = localStorage.getItem('roles');
    if (roles) {
      return roles.includes('admin');
    }

    return false;
  }

  get authenticated() {
    const expiresAt = localStorage.getItem('expiresAt');
    if (expiresAt) {
      return new Date().getTime() < Number(expiresAt);
    }
    return false;
  }

  get profile() {
    return {
      email: localStorage.getItem('email'),
      userId: localStorage.getItem('id'),
      avatar: localStorage.getItem('avatar'),
      roles: this.roles,
      emailVerified: localStorage.getItem('emailVerified') === 'true',
      ie: localStorage.getItem('ie'),
      provider: localStorage.getItem('provider'),
    };
  }

  get roles() {
    const value = localStorage.getItem('roles');
    return value ? value.split(',') : [];
  }
}
