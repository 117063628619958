import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { InputNumber, Space, Tooltip } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

// import MakePaymentButton from './MakePaymentButton';
import MakePaymentWithNMIButton from './MakePaymentWithNMIButton';
import MakePaymentWithPayPalButton from './MakePaymentWithPayPalButton';
import MakePaymentWithCryptoButton from './Crypto/MakePaymentWithCryptoButton';

const MIN_AMOUNT = 10;

export default function AddCredits({ defaultValue = 25, loading, callback, hidePayPal, secondaryTab, riskLevel = 0, emailVerified = false, fixedAmount }) {
  const { t } = useTranslation();
  var maxValue = 10000;
  if (riskLevel === 2) {
    maxValue = 50;
  } else if (riskLevel === 3) {
    maxValue = 25;
  }
  // const userId = localStorage.getItem('id');
  // const email = localStorage.getItem('email');

  useEffect(() => {
    setAmount(defaultValue);
  }, [defaultValue]);

  const [amount, setAmount] = useState(defaultValue);

  function handleSetAmount(value) {
    setAmount(parseInt(value) || MIN_AMOUNT);
  }

  async function delayedCallback() {
    if (callback) callback(true);
  }

  return <Space style={{ alignItems: 'flex-start', marginBottom: '-20px' }}>
    {!fixedAmount && <>
      {!hidePayPal && <div style={{ marginTop: '24px' }}>
        <Trans i18nKey='title.add' />
      </div>}
      <InputNumber
        style={{ marginTop: '20px' }}
        formatter={value => `$ ${value}`}
        value={amount}
        min={MIN_AMOUNT}
        max={loading ? amount : maxValue}
        step={50}
        onChange={handleSetAmount}
      />
      {' '}
    </>}
    <Space direction='vertical' >
      <Tooltip placement='top' title={<>
        {!emailVerified ?
          <Trans i18nKey='profile.emailUnverified' />
          :
          <>{riskLevel > 2 ?
            <><Trans i18nKey='profile.riskLevel' /></>
            :
            <><Trans i18nKey='title.fee' />: 2.9%</>
          }</>
        }
      </>}>
        {''}
        <MakePaymentWithNMIButton
          showIcon
          amount={amount}
          loading={loading}
          disabled={riskLevel > 2 || (!emailVerified && riskLevel > 1)}
          callback={delayedCallback}
        />
      </Tooltip>
      {/* <Tooltip placement='top' title={<>
        {!emailVerified ?
          <>Please Verify Your Email Address</>
          :
          <>{riskLevel > 2 ?
            <>Your account is currently flagged with a high-risk status for using this</>
            :
            <>Fee: 2.9%</>
          }</>
        }
      </>}>
        {''}
        <MakePaymentButton
          showIcon
          amount={amount}
          loading={loading}
          disabled={riskLevel > 2 || !emailVerified}
          onMakePayment={() => window.open(`https://buy.stripe.com/14k01O1nlcfm424fYY?prefilled_email=${email}&client_reference_id=${userId}`, 'noopener,noreferrer')}
        />
      </Tooltip> */}
      {!hidePayPal && <><Tooltip title={<>
        <Trans i18nKey='profile.hidePayPal' />
      </>} placement='bottom'>{''}<MakePaymentWithCryptoButton callback={callback} amount={amount} loading={loading} secondaryTab={secondaryTab} /></Tooltip></>}
    </Space>
    {!hidePayPal && riskLevel < 4 && <span><Tooltip title={t('title.fee', 'Fee') + ': 3.9%'} placement='top'>{''}
      <MakePaymentWithPayPalButton amount={amount} loading={loading} callback={delayedCallback} />
    </Tooltip></span>}
  </Space>;
}

AddCredits.propTypes = {
  loading: PropTypes.bool,
  hidePayPal: PropTypes.bool,
  creditCardButtonTitle: PropTypes.string,
  defaultValue: PropTypes.number,
  riskLevel: PropTypes.number,
  emailVerified: PropTypes.bool,
  secondaryTab: PropTypes.bool,
  callback: PropTypes.func,
  fixedAmount: PropTypes.bool,
};
