import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Input, message, Button } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { useTranslation, Trans } from 'react-i18next';

import Mixpanel from '../../analytics/mixpanel';
import { getCompanyHomePage } from '../../helpers/common';

const HOME_PAGE = getCompanyHomePage();

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default function ReferralProgram({ userId, disabled, referred }) {
  const referrer = userId ? btoa(userId) : '';
  const { t } = useTranslation();

  function onClickFromInput() {
    onCopy();
  }

  function onCopy() {
    Mixpanel.track('Copy referral link');

    var referrerLink = document.querySelector('.referrer-link');
    referrerLink.select();
    document.execCommand('copy');

    message.success(t('referralProgram.copyLink'));
  }

  return <>
    <Input
      readOnly
      className='referrer-link copieble'
      value={`${HOME_PAGE}/refer?referrer=${referrer}`}
      disabled={disabled}
      onClick={onClickFromInput}
    />
    <StyledButton
      title={t('copyToClipboard')}
      icon={<FontAwesomeIcon icon={faCopy} />}
      onClick={onCopy}
    />

    {referred && <><br /><Trans i18nKey='referralProgram.clients' />: {referred.toLocaleString()}</>}
  </>;
}

ReferralProgram.propTypes = {
  userId: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  referred: PropTypes.number,
};
