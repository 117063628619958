import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Button, notification } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import apiProfile from '../../api/apiProfile';

const layout = { labelCol: { span: 8 }, wrapperCol: { span: 16 } };

export default function ChangeEmailModal({ visible, onCancel, onChanged, authProvider, internalProvider }) {
  const [codeSent, setCodeSent] = useState(false);
  const [validCode, setValidCode] = useState(null);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  async function handleChangeEmail(email, code) {
    if (code === validCode) {
      try {
        setLoading(true);

        const data = await apiProfile.changeEmail(email);
        notification.success({ message: data });

        setCodeSent(false);
        onChanged(email);
      } catch (error) {
        notification.error({ message: error.message });
      } finally {
        setLoading(false);
      }
    } else {
      notification.error({ message: 'Invalid code' });
    }
  }

  async function onFinish({ email, code }) {
    if (codeSent) {
      await handleChangeEmail(email, code);
    } else {
      try {
        setLoading(true);

        const data = await apiProfile.requestEmailConfirmationCode(email);
        setValidCode(data.code);

        setCodeSent(true);
      } catch (error) {
        notification.error({ message: error.message });
      } finally {
        setLoading(false);
      }
    }
  }

  function handleOnCancel() {
    setCodeSent(false);
    onCancel();
  }

  return (
    <Modal
      destroyOnClose
      footer={null}
      title={t('title.changeEmail')}
      open={visible}
      onCancel={handleOnCancel}
    >
      {!internalProvider && <>* <Trans i18nKey='changeEmail.message' />{authProvider}.<br /><br /></>}
      <Form name='change-email' {...layout} onFinish={onFinish}>
        <Form.Item
          label={t('changeEmail.newEmail')}
          name='email'
          validateTrigger='onSubmit'
          rules={[
            { required: true, message: 'Please enter your new email' },
            { type: 'email', message: 'Please enter valid email' },
          ]}
        >
          <Input style={{ width: 265 }} disabled={codeSent} />
        </Form.Item>
        {codeSent ? <>
          <Form.Item
            label='Confirmation code'
            name='code'
            validateTrigger='onSubmit'
            rules={[
              { required: true, message: 'Please enter the code from the email we\'ve sent' },
              { len: 6, message: 'Invalid code' },
            ]}
          >
            <Input style={{ width: 215 }} />
          </Form.Item>
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button htmlType='submit' type='primary' title='Change Email Address' loading={loading}>
              <Trans i18nKey='title.changeEmail' />
            </Button>
          </Form.Item>
        </> : (
          <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 8 }}>
            <Button loading={loading} htmlType='submit' title='Send Confirmation Code'><Trans i18nKey='title.sendCode' /></Button>
          </Form.Item>
        )}
      </Form>
    </Modal>
  );
}

ChangeEmailModal.propTypes = {
  visible: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onChanged: PropTypes.func.isRequired,
  authProvider: PropTypes.string,
  internalProvider: PropTypes.bool,
};
