import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Popconfirm, Button, Row, Col, Tooltip } from 'antd';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';

import ChangeEmailModal from './ChangeEmailModal';

const StyledButton = styled(Button)`
  margin-left: 8px;
`;

export default function ProfileDangerousActions({ onPasswordChangeRequest, onRemove, onEmailChanged, loading, passwordChangeRequested, authProvider }) {
  const navigate = useNavigate();
  const [isChangeEmailModalVisible, setIsChangeEmailModalVisible] = useState(false);
  const internalProvider = authProvider ? authProvider === 'auth0' : true;
  const notChangeableTooltip = internalProvider ? '' : `Account created with ${authProvider}`;
  const { t } = useTranslation();

  function handleOnEmailChanged(email) {
    setIsChangeEmailModalVisible(null);
    onEmailChanged(email);
  }

  const handleLogout = () => {
    navigate('/logout');
  };

  return <>
    <Row>
      <Col span={24} style={{ textAlign: 'right' }}>
        <Popconfirm
          title={t('profile.areYouSureRemoveAccount')}
          onConfirm={onRemove}
          cancelText={t('title.cancel')}
        >
          <StyledButton danger title='Remove Account' loading={loading}>
            <Trans i18nKey='title.removeAccount' />
          </StyledButton>
        </Popconfirm>
        <Tooltip title={notChangeableTooltip}>
          <Popconfirm
            title={t('profile.areYouChangepassword')}
            disabled={!internalProvider}
            onConfirm={onPasswordChangeRequest}
            cancelText={t('title.cancel')}
          >
            <StyledButton
              ghost
              type='primary'
              title={t('title.changePassword')}
              loading={loading}
              disabled={passwordChangeRequested || !internalProvider}
            ><Trans i18nKey='title.changePassword' />
            </StyledButton>
          </Popconfirm>
        </Tooltip>
        <StyledButton
          ghost
          type='primary'
          title={t('profile.changeEmail')}
          loading={loading}
          disabled={isChangeEmailModalVisible === null}
          onClick={() => setIsChangeEmailModalVisible(true)}
        ><Trans i18nKey='title.changeEmail' />
        </StyledButton>
        <Popconfirm
          title={t('profile.areYouLogOut')}
          onConfirm={handleLogout}
          cancelText={t('title.cancel')}
        >
          <StyledButton
            ghost
            title={t('title.logOut')}
            type='primary'
            icon={<LogoutOutlined />}
          ><Trans i18nKey='title.logOut' /></StyledButton>
        </Popconfirm>
      </Col>
    </Row>
    <ChangeEmailModal
      visible={isChangeEmailModalVisible}
      onCancel={() => setIsChangeEmailModalVisible(false)}
      onChanged={handleOnEmailChanged}
      authProvider={authProvider}
      internalProvider={internalProvider}
    />
  </>;
}

ProfileDangerousActions.propTypes = {
  onPasswordChangeRequest: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEmailChanged: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  passwordChangeRequested: PropTypes.bool,
  authProvider: PropTypes.string,
};
