import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Button, Space, Tooltip, Select } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { isEmpty } from 'lodash';
import { useTranslation, Trans } from 'react-i18next';

import Mixpanel from '../../analytics/mixpanel';
import SelectType from '../Common/SelectType';
import SelectCityOrTown from '../Common/SelectCityOrTown';
import SelectCounty from '../Common/SelectCounty';
import SelectCountry from '../Common/SelectCountry';
import SelectState from '../Common/SelectState';
import GeoFiltersItems from './GeoFiltersItems';
import ExportButton from '../Common/ExportButton';
import SearchStatus from '../Common/SearchStatus';
import AdvancedFiltersModal from './AdvancedFiltersModal/AdvancedFiltersModal';
import { useSelector } from 'react-redux';

// const mobileFilterCountryCodes = new Set([
//   'IT', 'UA', 'FR', 'ES', 'SE', 'SZ', 'TR', 'AE', 'BR',
//   'IN', 'ID', 'PH', 'IE', 'IL', 'PL', 'PT', 'RU', 'SG',
//   'ZA', 'TW', 'TH', 'VN', 'CO', 'EG', 'HK', 'MY', 'NL',
//   'NZ', 'PE', 'CL', 'US',
// ]);

export default function Search({ searchParams, onChange, size, loading, onSearch, exporting, total, loadingTotal, hideControls, searched, setSearched, placesShowing }) {
  const { cc, state, c, county, t, it, geoFilters, addedFrom, price, rating, reviews, revenue, employees, verified, postalCode, businessStatus, public: isPublic, email, contactCategory, phone, emailAndPhone, site, domain, name, os_id, located, att, area, generator, businessOnly } = searchParams;
  const isGeoFilters = geoFilters && geoFilters.length > 0;
  const exportDisabled = loading || loadingTotal || total === 0 || placesShowing === 0;
  const advancedParametersCount = [addedFrom, postalCode, price, rating, reviews, revenue, employees, businessStatus, isPublic, verified, email, contactCategory, phone, emailAndPhone, site, domain, name, it, att, os_id, located, area, generator, businessOnly].filter(x => x).length;
  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const { t: translate } = useTranslation();

  const selectedItems = useSelector((state) => state.items.selectedItems || []);

  const exportButtonTitle = selectedItems.length > 0 ? `Download (${selectedItems.length})` : 'Download';

  function onSearchHandle() {
    onSearch({ ...searchParams });
    setSearched(true);
    Mixpanel.track('Search click', searchParams);
  }

  function onChangeHandle(value) {
    onChange(value);
    setSearched(false);
  }

  function onCountryChange(countryCode) {
    delete searchParams.state;
    delete searchParams.c;
    delete searchParams.county;
    delete searchParams.mobile;

    onChangeHandle({ ...searchParams, cc: countryCode });
  }

  function onStateChange(newState) {
    delete searchParams.c;
    delete searchParams.county;
    onChangeHandle({ ...searchParams, state: newState });
  }

  const handleFilterOpenModal = () => {
    setIsFiltersModalOpen(true);
  };

  const handleFilterCloseModal = () => {
    setIsFiltersModalOpen(false);
  };

  return <Space size={16} direction={'vertical'} className='w-100'>
    <Form name='search-form' className='top-search-bar'>
      <Space size={24} align='start' style={{ width: '100%', justifyContent: 'space-between' }}>
        {hideControls ? (
          <Tooltip
            title={
              searched
                ? loadingTotal && 'Calculating results'
                : 'Apply filters by clicking search'
            }
          >
            <ExportButton
              productName='Places'
              type={searched ? 'primary' : 'default'}
              totalResults={total}
              loading={exporting || loadingTotal}
              searchParams={searchParams}
              disabled={exportDisabled || !searched}
            />
          </Tooltip>
        ) : (
          <>
            <Space size={8} wrap align='start'>
              <SelectType
                showQucikButtons
                size='large'
                value={t}
                onChange={(v) => onChangeHandle({ ...searchParams, t: v })}
              />

              {isGeoFilters ? (
                <GeoFiltersItems value={geoFilters} />
              ) : (
                <>
                  <SelectCountry value={cc} onChange={onCountryChange} />

                  {cc === 'US' && (
                    <SelectState
                      placeholder='State'
                      value={state}
                      onChange={onStateChange}
                    />
                  )}

                  <SelectCityOrTown
                    isPlaces
                    filters={{ cc, state }}
                    value={c}
                    onChange={(value) =>
                      onChangeHandle({ ...searchParams, c: value })
                    }
                  />

                  {!isEmpty(c) && (
                    <SelectCounty
                      isPlaces
                      filters={{ cc, state, c }}
                      value={county}
                      onChange={(value) =>
                        onChangeHandle({ ...searchParams, county: value })
                      }
                    />
                  )}
                </>
              )}
              <Select
                size='large'
                open={false}
                placeholder={translate('filters.moreFilters') + (advancedParametersCount > 0 ? ` (${advancedParametersCount})` : '')}
                onClick={handleFilterOpenModal}
                style={{ cursor: 'pointer' }}
              />
            </Space>
            <Space size={8} align={'center'}>
              <Button
                className='search-button'
                htmlType='submit'
                title='Search'
                type={searched ? 'default' : 'primary'}
                size='large'
                loading={loading}
                icon={<SearchOutlined />}
                onClick={onSearchHandle}
              >
                <Trans i18nKey='title.search' />
              </Button>

              <Tooltip
                title={
                  searched
                    ? loadingTotal && 'Calculating results'
                    : 'Apply filters by clicking search'
                }
              >
                <ExportButton
                  title={exportButtonTitle}
                  productName='Places'
                  type={searched ? 'primary' : 'default'}
                  totalResults={total}
                  loading={exporting || loadingTotal}
                  searchParams={searchParams}
                  disabled={exportDisabled || !searched}
                />
              </Tooltip>
            </Space>
          </>
        )}
      </Space>
    </Form>
    <SearchStatus total={total} loading={loading} loadingTotal={loadingTotal} searched={searched} />
    <AdvancedFiltersModal
      isModalOpen={isFiltersModalOpen}
      onClose={handleFilterCloseModal}
      searchParams={searchParams} size={size}
      onChangeHandle={onChangeHandle}
    />
  </Space>;
}

Search.propTypes = {
  searchParams: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  size: PropTypes.string,
  loading: PropTypes.bool,
  exporting: PropTypes.bool,
  total: PropTypes.number,
  loadingTotal: PropTypes.bool,
  hideControls: PropTypes.bool,
  setSearched: PropTypes.func,
  searched: PropTypes.bool,
  placesShowing: PropTypes.number,
};
