import config from 'react-global-configuration';

const GTM_TOKEN = config.get('gtmToken');
const GA_TOKEN = config.get('gaToken');

function sendEvent(eventName, sendTo, additionalParams = {}) {
  window.gtag('event', eventName, {
    send_to: sendTo,
    ...additionalParams,
  });
}

export function sendBeginCheckoutEvent(value = 1.0, currency = 'USD', paymentMethod = '') {
  sendEvent(`begin_checkout_${paymentMethod}`, GTM_TOKEN, { value, currency });
  sendEvent('conversion', GA_TOKEN, { value, currency });
}

export function sendPurchaseEvent(value = 1.0, currency = 'USD', paymentMethod = '', transactionId = '') {
  sendEvent(`purchase_${paymentMethod}`, GTM_TOKEN, { value, currency, transaction_id: transactionId });
  sendEvent('conversion', GA_TOKEN, { value, currency, transaction_id: transactionId });
}

export function sendFailedPurchaseEvent(value = 1.0, currency = 'USD', paymentMethod = '') {
  sendEvent(`failed_purchase_${paymentMethod}`, GTM_TOKEN, { value, currency });
}

export function sendSignUpEvent() {
  sendEvent('sign_up', GTM_TOKEN);
  sendEvent('conversion', GA_TOKEN);
}

export function sendLoginEvent() {
  sendEvent('login', GTM_TOKEN);
  sendEvent('conversion', GA_TOKEN);
}
