import React from 'react';
import PropTypes from 'prop-types';
import { Statistic } from 'antd';
import { useTranslation, Trans } from 'react-i18next';

import AddCredits from './AddCredits/AddCredits';

export default function Balance({ loading, profile, onPaymentMade, emailVerified }) {
  const { balance, coupon = {}, risk_level = 0 } = profile;
  const { duration_in_months, duration } = coupon;
  const couponDuration = duration_in_months ? `for ${duration_in_months} months` : duration;
  const { name: couponName } = coupon;
  const { t } = useTranslation();

  return <>
    <Statistic
      title={t('profile.remainingCredits')}
      value={balance}
      precision={2}
      prefix='$' />
    <br />
    <br />
    <h3> <Trans i18nKey='title.topUp' /></h3>
    <AddCredits loading={loading} callback={onPaymentMade} riskLevel={risk_level} emailVerified={emailVerified} />
    <br />
    <br />
    {balance === 0 && <>
      <p className='help-p2'>
        * <Trans i18nKey='profile.usingCredits' />.
      </p>
    </>}
    <br />

    {couponName && <>
      <Statistic title={t('profile.specialOffer')} value={couponName} suffix={couponDuration} /><br /><br />
    </>}
  </>;
}

Balance.propTypes = {
  loading: PropTypes.bool,
  profile: PropTypes.object.isRequired,
  onPaymentMade: PropTypes.func.isRequired,
  emailVerified: PropTypes.bool,
};
