import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Avatar, Typography, Space } from 'antd';
import { ShopOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';

import { MdCorporateFare, MdOutlineLocationOn } from 'react-icons/md';

import Rating from '../Rating';
import Address from '../../../Common/Address';
import Categories from '../../../Common/Categories';
import { handleOnCopy } from '../../../../utils/helper';
import PlaceTitle from '../PlaceTitle';
import { FiLink, FiMail, FiPhone } from 'react-icons/fi';

import { HiUserGroup } from 'react-icons/hi';
import { RiMoneyDollarCircleLine } from 'react-icons/ri';

import './PlaceCard.scss';
import { FacebookIcon, InstagramIcon, LinkedInIcon, TickTokIcon, TwitterIcon } from '../../../../assets/icons';
import { useDimensions } from '../../../../hooks/useDimensions';
import { useMedia } from '../../../../hooks/useMedia';
import HidedValue from '../../../Common/HidedValue';

let formatter = Intl.NumberFormat('en', { notation: 'compact' });

const { Link } = Typography;

const FULL_DATA_TIP = 'Click the "Export" button to get this data.';

export default function PlaceCard({ value, onSearchParamsChange, isSelected }) {
  const {
    photo, types, located_in, located_os_id,
    site, phone, phone_type, linkedin, facebook, twitter, instagram, ticktok, rating_history,
    email_1, email_2, email_3, email_1_title, email_1_first_name,
    price_range, corp_revenue, corp_employees, corp_founded_year, corp_is_public,
  } = value;
  const [modalVisible, setModalVisible] = useState(false);
  const { isMobile, isNotMobile } = useMedia();
  const { t } = useTranslation();

  const domain = site ? site.replace('www.', '').replace('http://', '').replace('https://', '').split('/')[0] : null;

  function handleLocatedInClick() {
    onSearchParamsChange({ located: located_os_id, type: null });
  }

  function onExport() {
    setModalVisible(!modalVisible);
  }

  const width = useDimensions();

  return (
    <Space size={16} direction='vertical' className={`card ${isSelected ? 'selected' : ''}`} onCopy={handleOnCopy}>
      <div className='card-wrapper'>
        <Space size={16} direction='vertical' className='left-container'>
          <Avatar className='avatar' shape='square' size={130} src={photo} icon={<ShopOutlined />} />
          <Tooltip title={FULL_DATA_TIP} className='icons'>
            <Space size={4}>
              {linkedin && <LinkedInIcon />} {facebook && <FacebookIcon />} {twitter &&
                <TwitterIcon />} {instagram && <InstagramIcon />} {ticktok &&
                  <TickTokIcon />}
            </Space>
          </Tooltip>
          {isMobile && <div className='button-container mobile'>
            {width > 570 && <Rating value={value} />} {price_range && price_range}
            <Tooltip title={t('cardInfo')}>
              {corp_founded_year &&
                <Space size={4} className='contact-data-container'><MdCorporateFare /> <strong><Trans i18nKey='title.founded' />:</strong>{corp_founded_year}
                </Space>}
              {corp_employees && <Space size={4} className='contact-data-container'><HiUserGroup />
                <span className='bold'><Trans i18nKey='title.employees' />:</span> {formatter.format(corp_employees)}</Space>}
              {corp_revenue && <Space size={4} className='contact-data-container'><RiMoneyDollarCircleLine />
                <span className='bold'><Trans i18nKey='title.revenue' />:</span> {formatter.format(corp_revenue)}</Space>}
              {(corp_is_public === true || corp_is_public === false) &&
                <Space size={4} className='contact-data-container'><MdCorporateFare />
                  <span className='bold'><Trans i18nKey='title.type' />:</span> {corp_is_public ? 'Public' : 'Private'}</Space>}
            </Tooltip>
          </div>}
        </Space>

        <Space className='right-container'>
          <Space direction='vertical'>
            <div className='title-container'>
              <PlaceTitle value={value} onExport={onExport} />
              <Categories value={types} />
            </div>
            {isNotMobile && <div className='desktop'>{width < 1040 && <Rating value={value} />}{price_range && price_range}</div>}
            {isMobile && <div className='mobile' style={{ marginTop: 10 }}>{width < 570 &&
              <Rating value={value} />}{price_range && price_range}</div>}
            <Tooltip
              title={<>
                {rating_history && <>history:<br />{rating_history.map((el, i) => <div key={`${el}-${i}`}>{el}<br />
                </div>)}</>}
              </>}>
            </Tooltip>

            <Space direction='vertical'>
              <Space className='contact-data-container'>
                <MdOutlineLocationOn size={23} />
                <Address
                  value={value} />
              </Space>
              {located_in && <Space className='contact-data-container'>
                <MdOutlineLocationOn size={22} />
                <Link onClick={handleLocatedInClick}>{located_in}</Link>
              </Space>}
              {domain && <Space className='contact-data-container'>
                <FiLink size={22} />
                <HidedValue value={`https://${domain}`} />
              </Space>}
              {phone && <Space className='contact-data-container'>
                <FiPhone size={22} />
                <HidedValue value={phone} /> {phone_type && <>({phone_type})</>}
              </Space>}
            </Space>
          </Space>

          {isNotMobile && <Space size={16} className='button-container desktop'>
            {width > 1040 && <Rating value={value} />}{price_range && price_range}
            <Tooltip title={t('cardInfo')}>
              <Space direction='vertical'>
                {corp_founded_year &&
                  <Space size={4} className='contact-data-container'><MdCorporateFare />
                    <span className='bold'><Trans i18nKey='title.founded' />:</span>{corp_founded_year}
                  </Space>}
                {corp_employees && <Space size={4} className='contact-data-container'><HiUserGroup />
                  <span className='bold'><Trans i18nKey='title.employees' />:</span> {formatter.format(corp_employees)}</Space>}
                {corp_revenue && <Space size={4} className='contact-data-container'><RiMoneyDollarCircleLine />
                  <span className='bold'><Trans i18nKey='title.revenue' />:</span> {formatter.format(corp_revenue)}</Space>}
                {(corp_is_public === true || corp_is_public === false) && <Space size={4} className='contact-data-container'>
                  <MdCorporateFare />
                  <span className='bold'><Trans i18nKey='title.type' />:</span> {corp_is_public ? 'Public' : 'Private'}</Space>}
              </Space>
            </Tooltip>
          </Space>}
        </Space>
      </div>
      {email_1 && isNotMobile &&
        <Space align='center' className='align-items-space contact-data-container'>
          <FiMail size={22} />
          <HidedValue value={email_1} />
          {email_1_title && `(${email_1_title}:`}
          <HidedValue value={email_1_first_name} />
          {email_2 && `) + ${email_3 ? '2' : '1'} more`}
        </Space>}
      {email_1 && isMobile &&
        <Space align='center' className='align-items-space contact-data-container'>
          <FiMail size={22} />
          <HidedValue value={email_1} />
          {email_2 && `+ ${email_3 ? '2' : '1'} more`}
        </Space>}
    </Space>
  );
}

PlaceCard.propTypes = {
  value: PropTypes.object.isRequired,
  onSearchParamsChange: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};
