import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Layout, Menu } from 'antd';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DatabaseOutlined, HistoryOutlined } from '@ant-design/icons';
import { faQuestion, faBug, faUser, faMailBulk, faTimesCircle, faComments, faFileAlt, faCreditCard, faFileInvoiceDollar, faPlug } from '@fortawesome/free-solid-svg-icons';
import { Trans, useTranslation } from 'react-i18next';

import { getCompanyHomePage, getCompanyConfig } from './../../helpers/common';
import Avatar from './../../Components/Common/Avatar';
import Href from '../../shared/Components/Href';
import FeatureRequestModal from '../Others/FeatureRequestModal';

import AppLogo from '../../assets/app/logo-white.png';

const StyledLogo = styled.img`
	width: 160px;
`;

const StyledDiv = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
  width: 100%;
	padding: 15px 0 15px 0;
`;

const COMPANY_CONFIG = getCompanyConfig();

const itemStyle = { fontSize: '15px' };

const menuStyle = { backgroundColor: COMPANY_CONFIG.colors.main };

const StyledText = styled.span`
  padding-left: 12px;
`;

const StyledText2 = styled.span`
  padding-left: 5px;
`;

const theme = 'dark';

function DoubleIcon({ children }) {
  return <span style={{ display: 'inline-block', position: 'relative' }}>{children}</span>;
}

DoubleIcon.propTypes = { children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]).isRequired };

function appendLanguageToUrl(url) {
  const { i18n } = useTranslation();
  const ln = (i18n.language || 'en').split('-')[0];

  if (ln !== 'en') {
    return `${url}${url.includes('?') ? '&' : '?'}ln=${ln}`;
  }

  return url;
}

export default function Sider({ auth }) {
  const location = useLocation();
  const [featureRequestOpen, setFeatureRequestOpen] = useState(false);
  const [isHelpOpened, setIsHelpOpened] = useState(false);
  const { profile = {}, authenticated = false } = auth;
  const { email, avatar } = profile;
  const { pathname } = location;
  const selectedKeys = pathname === '/' ? '/googleMaps' : pathname;

  function onHelpClick() {
    if (isHelpOpened) {
      window.Chatra('closeChat');
    } else {
      window.Chatra('updateIntegrationData', { email });
      window.Chatra('openChat', true);
    }

    setIsHelpOpened(!isHelpOpened);
  }

  function showUserName(email) {
    if (email) {
      return email.split('@')[0];
    } else {
      return email;
    }
  }

  return <>
    <Layout.Sider width='240' style={menuStyle}>
      <StyledDiv>
        <StyledLogo src={AppLogo} alt='targetron logo' />
      </StyledDiv>
      <Menu theme={theme} style={menuStyle} selectedKeys={selectedKeys} mode='inline'>
        <Menu.Item key='/local-businesses' style={itemStyle}>
          <Href external href={appendLanguageToUrl('/local-businesses')}>
            <DatabaseOutlined />
            <StyledText2><Trans i18nKey='sider.business' /></StyledText2>
          </Href>
        </Menu.Item>
        <Menu.Item key='/profile' style={itemStyle}>
          <Href href={appendLanguageToUrl('/profile')}>
            {authenticated ?
              <><Avatar src={avatar} email={email} size='small' /><StyledText>{showUserName(email)}</StyledText></> :
              <><FontAwesomeIcon style={itemStyle} icon={faUser} /><StyledText><Trans i18nKey='title.profile'>Profile</Trans></StyledText></>
            }
          </Href>
        </Menu.Item>

        <Menu.Item key='/exports' style={itemStyle}>
          <Href href={appendLanguageToUrl('/exports')}>
            <HistoryOutlined />
            <StyledText2><Trans i18nKey='title.exports' /></StyledText2>
          </Href>
        </Menu.Item>
        <Menu.Item key='/billing-info' style={itemStyle}>
          <Href href={appendLanguageToUrl('/billing-info')}>
            <FontAwesomeIcon style={itemStyle} icon={faCreditCard} />
            <StyledText><Trans i18nKey='sider.billing' /></StyledText>
          </Href>
        </Menu.Item>
        <Menu.Item key='/invoices' style={itemStyle}>
          <Href href={appendLanguageToUrl('/invoices')}>
            <FontAwesomeIcon style={itemStyle} icon={faFileInvoiceDollar} />
            <StyledText><Trans i18nKey='title.invoices' /></StyledText>
          </Href>
        </Menu.Item>
        <Menu.Item key='/api-docs' style={itemStyle}>
          <Href href={appendLanguageToUrl('/api-docs')}>
            <FontAwesomeIcon style={itemStyle} icon={faFileAlt} />
            <StyledText><Trans i18nKey='title.apiDocs' /></StyledText>
          </Href>
        </Menu.Item>
        <Menu.Item key='/integrations' style={itemStyle}>
          <Href href={appendLanguageToUrl('/integrations')}>
            <FontAwesomeIcon style={itemStyle} icon={faPlug} />
            <StyledText><Trans i18nKey='title.integrations' /></StyledText>
          </Href>
        </Menu.Item>
      </Menu>
      <Menu theme={theme} mode='inline' style={menuStyle} selectable={false}>
        <Menu.SubMenu key='help' title={<><FontAwesomeIcon style={itemStyle} icon={faQuestion} /><StyledText><Trans i18nKey='sider.help'>Help</Trans></StyledText></>}>
          <Menu.Item key='/feature-request' onClick={() => setFeatureRequestOpen(!featureRequestOpen)} style={itemStyle}>
            <FontAwesomeIcon style={itemStyle} icon={faBug} />
            <StyledText><Trans i18nKey='sider.report' /></StyledText>
          </Menu.Item>
          <Menu.Item key='/support' onClick={onHelpClick} style={itemStyle}>
            {isHelpOpened ? (<FontAwesomeIcon style={itemStyle} icon={faTimesCircle} />) : (<FontAwesomeIcon style={itemStyle} icon={faComments} />)}
            <StyledText><Trans i18nKey='sider.support' /></StyledText>
          </Menu.Item>
          <Menu.Item key='/contact-us' style={itemStyle}>
            <a target='_blank' rel='noopener noreferrer' href={`${getCompanyHomePage()}/contact-us`}>
              <FontAwesomeIcon style={itemStyle} icon={faMailBulk} />
              <StyledText><Trans i18nKey='sider.contact' /></StyledText>
            </a>
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    </Layout.Sider>
    <FeatureRequestModal open={featureRequestOpen} onChange={setFeatureRequestOpen} />
  </>;
}

Sider.propTypes = {
  auth: PropTypes.object.isRequired,
};
