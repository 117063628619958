import React from 'react';
import PropTypes from 'prop-types';
import { Rate } from 'antd';
import styled from 'styled-components';

const StyledRate = styled(Rate)`
  font-size: 20px;
`;

const StyledRatingWrapper = styled.div`
  margin: 0px 0 15px 0;
  display: flex;
  align-items: center;
  gap: 10px;
`;

export default function Rating({ value }) {
  const { rating, reviews } = value;

  return (
    <StyledRatingWrapper>
      {reviews && <h4 className='h4 bold'>{rating + ' '}</h4>}
      <StyledRate allowHalf disabled value={rating}/>
      {reviews && <h4 className='h4 bold'>({reviews})</h4>}
    </StyledRatingWrapper>
  );
}

Rating.propTypes = {
  value: PropTypes.object.isRequired,
};
