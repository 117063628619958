import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Empty } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import Moment from 'react-moment';
import { useTranslation, Trans } from 'react-i18next';

import { fetchInvoices } from '../../redux/actions/paymentActions';
import InvoiceStatus from './InvoiceStatus';
import Href from '../../shared/Components/Href';

export default function Invoices() {
  const invoices = useSelector(state => state.paymentReducer.invoices);
  const hasMore = useSelector(state => state.paymentReducer.hasMoreInvoices);
  const loading = useSelector(state => state.paymentReducer.loading);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [currentPageSize, setCurrentPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [lastIds, setLastIds] = useState(['']);
  const currentLastId = (invoices.length > 0) ? invoices[invoices.length - 1].id : '';

  const invoicesColumns = [
    {
      title: t('title.number'),
      dataIndex: 'hosted_invoice_url',
      key: 'hosted_invoice_url',
      render: (url, record) => url ? <Href href={url} external>{record.number}</Href> : record.number,
    },
    {
      title: t('title.created'),
      dataIndex: 'created',
      key: 'created',
      render: created => <Moment format='YYYY/MM/DD HH:mm' unix>{created}</Moment>,
    },
    {
      title: t('title.amount2') + '$',
      dataIndex: 'total',
      key: 'total',
    },
    {
      title: t('invoices.amountPaid') + '$',
      dataIndex: 'amount_paid',
      key: 'amount_paid',
    },
    {
      title: t('title.status'),
      dataIndex: 'status',
      key: 'status',
      render: (_, record) => <InvoiceStatus invoice={record} />,
    },
    {
      title: 'PDF',
      dataIndex: 'invoice_pdf',
      key: 'invoice_pdf',
      render: text => text && <a href={text}><FilePdfOutlined /></a>,
    },
  ];

  useEffect(() => {
    document.title = t('title.invoices');
    dispatch(fetchInvoices());
  }, []);

  function onPagination({ current, pageSize }) {
    if (pageSize !== currentPageSize || current !== currentPage) {
      if (pageSize !== currentPageSize) {
        setLastIds(['']);
      }

      if (current > lastIds.length) {
        setLastIds([...lastIds, currentLastId]);
        dispatch(fetchInvoices(currentLastId, pageSize));
      } else {
        dispatch(fetchInvoices(lastIds[current - 1], pageSize));
      }

      setCurrentPage(current);
      setCurrentPageSize(pageSize);
    }
  }

  return <>
    <Table
      loading={loading}
      title={() => <h3><Trans i18nKey='title.invoices' /></h3>}
      columns={invoicesColumns}
      dataSource={invoices}
      rowKey='id'
      pagination={{
        pageSize: currentPageSize,
        current: currentPage,
        total: lastIds.length * currentPageSize + hasMore - (currentPage < lastIds.length),
      }}
      onChange={onPagination}
      locale={{ emptyText: <Empty description={t('invoices.emptyInvoices')} /> }}
    />
    <p className='help-p1'>
      * <Trans i18nKey='invoices.help1' />
    </p>
    <p className='help-p1'>
      * <Trans i18nKey='invoices.help2' />
    </p>
  </>;
}
