import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation, Trans } from 'react-i18next';

import Avatar from '../Common/Avatar';
import { useSelector } from 'react-redux';
import { useDimensions } from '../../hooks/useDimensions';

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  float: right;
`;

const StyledUserInfoWrappper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledUserInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  gap: 4px;
  font-size: 20px;
  height: fit-content;
  white-space: nowrap;

  p {
    font-weight: bold;
    margin: -5px;
  }
`;

const StyledAvatar = styled.div`
  cursor: pointer;
`;

export default function ProfileHeader({ auth }) {
  const { balance = 0 } = useSelector(state => state.profileReducer.profile);

  const { profile = {}, authenticated = false } = auth;
  const { email, avatar } = profile;

  const width = useDimensions();
  const { t } = useTranslation();

  function toProfile() {
    if (!authenticated && window.self !== window.top) {
      window.open('/profile', '_blank').focus();
    } else {
      window.location = '/profile';
    }
  }

  return <>
    <StyledDiv>
      {authenticated ?
        <StyledUserInfoWrappper>
          <StyledAvatar onClick={toProfile}>
            <Avatar size={width > 1155 ? 50 : 30} src={avatar} email={email} />
          </StyledAvatar>
          <StyledUserInfo className='desktop'>
            <p>${(balance.toFixed(2))}</p>
            <span><Trans i18nKey='catalog.header' /></span>
          </StyledUserInfo>
        </StyledUserInfoWrappper> :
        <Button
          type='link'
          title='Login to your account'
          icon={<UserOutlined />}
          onClick={toProfile}
        ><Trans i18nKey='title.login' /></Button>
      }
    </StyledDiv>
  </>;
}

ProfileHeader.propTypes = {
  auth: PropTypes.object.isRequired,
};
