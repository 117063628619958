export const ratingMarksMinMax = [0, 5];
export const ratingMarks = {
  0: ratingMarksMinMax[0].toString(),
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: ratingMarksMinMax[1].toString(),
};

export const reviewsMarksMinMax = [0, 1000];
export const reviewsMarks = {
  0: reviewsMarksMinMax[0].toString(),
  200: '200',
  400: '400',
  600: '600',
  800: '800',
  1000: reviewsMarksMinMax[1].toString(),
};

export const reviewsMarksWithPlus = { 1000: '1000+', ...reviewsMarks };

export const revenueValues = [0, 1000000, 10000000, 100000000, 1000000000];
export const revenueMarksMinMax = [revenueValues[0], revenueValues[revenueValues.length - 1]];
export const revenueMarks = {
  0: '0',
  1: '1M',
  2: '10M',
  3: '100M',
  4: '1B+',
};

export const employeesValues = [0, 10, 100, 1000, 10000, 100000];
export const employeesMarksMinMax = [employeesValues[0], employeesValues[employeesValues.length - 1]];
export const employeesMarks = {
  0: '0',
  1: '10',
  2: '100',
  3: '1k',
  4: '10k',
  5: '100k+',
};

export const emailAndPhoneUniquePhoneOptions = new Set([
  'enter_phone',
  'with_phone',
  'email_and_phone',
]);

export const emailAndPhoneUniqueDomainOptions = new Set([
  'with_email',
  'email_and_phone',
]);

export const businessStatusOptions = [
  { key: 'operational', value: 'operational', label: 'Operational' },
  { key: 'closed_temporarily', value: 'closed_temporarily', label: 'Temporarily closed' },
  { key: 'closed_permanently', value: 'closed_permanently', label: 'Permanently closed' },
];

export const websiteOptions = [
  { key: 'true', value: 'true', label: 'With website' },
  { key: 'false', value: 'false', label: 'Without website' },
  { key: 'domain', value: 'domain', label: 'Enter domain' },
];

export const contactCategoryOptions = [
  { key: 'decision makers', value: 'decision makers', label: 'Decision makers' },
  { key: 'influencers', value: 'influencers', label: 'Influencers' },
  { key: 'procurement/purchasing', value: 'procurement/purchasing', label: 'Procurement/purchasing' },
  { key: 'technical', value: 'technical', label: 'Technical' },
  { key: 'finance', value: 'finance', label: 'Finance' },
  { key: 'operations', value: 'operations', label: 'Operations' },
  { key: 'marketing', value: 'marketing', label: 'Marketing' },
  { key: 'sales', value: 'sales', label: 'Sales' },
  { key: 'maintenance', value: 'maintenance', label: 'Maintenance' },
  { key: 'human resources', value: 'human resources', label: 'Human resources' },
  { key: 'legal and compliance', value: 'legal and compliance', label: 'Legal and compliance' },
  { key: 'supply chain/logistics', value: 'supply chain/logistics', label: 'Supply chain/logistics' },
  { key: 'education/training', value: 'education/training', label: 'Education/training' },
];

export const cmsGeneratorOptions = [
  { key: 'wordpress', value: 'wordpress', label: 'WordPress' },
  { key: 'wix.com', value: 'wix.com', label: 'Wix.com' },
  { key: 'go daddy', value: 'go daddy', label: 'GoDaddy' },
  { key: 'mywebsite now', value: 'mywebsite now', label: 'MyWebsite Now (IONOS)' },
  { key: 'joomla', value: 'joomla', label: 'Joomla' },
  { key: 'divi', value: 'divi', label: 'Divi' },
  { key: 'big cartel', value: 'big cartel', label: 'Big Cartel' },
  { key: 'google', value: 'google', label: 'Site Kit by Google' },
  { key: 'e-monsite', value: 'e-monsite', label: 'E-monsite' },
  { key: 'studio', value: 'studio', label: 'STUDIO' },
];

export const emailAndPhoneOptions = [
  { key: 'with_email', value: 'with_email', label: 'With email' },
  { key: 'without_email', value: 'without_email', label: 'Without email' },
  { key: 'with_phone', value: 'with_phone', label: 'With phone' },
  { key: 'without_phone', value: 'without_phone', label: 'Without phone' },
  { key: 'enter_phone', value: 'enter_phone', label: 'Enter phone' },
  { key: 'email_and_phone', value: 'email_and_phone', label: 'With email and phone' },
  { key: 'email_or_phone', value: 'email_or_phone', label: 'With email or phone' },
];

export const verificationOptions = [
  { value: undefined, label: 'All' },
  { value: 'true', label: 'Verified' },
  { value: 'false', label: 'Unverified' }
];

export const companyTypeOptions = [
  { value: undefined, label: 'All' },
  { value: 'true', label: 'Public' },
  { value: 'false', label: 'Private' }
];

export const areaOptions = [
  { value: undefined, label: 'All' },
  { value: 'true', label: 'Area service (without location)' },
  { value: 'false', label: 'Physical location' }
];
