export const SELECT_ITEM = 'SELECT_ITEM';
export const DESELECT_ITEM = 'DESELECT_ITEM';
export const CLEAR_SELECTION = 'CLEAR_SELECTION';

export const selectItem = (id) => {
  return { type: SELECT_ITEM, payload: id };
};

export const deselectItem = (id) => {
  return { type: DESELECT_ITEM, payload: id };
};

export const clearSelection = () => {
  return { type: CLEAR_SELECTION };
};
